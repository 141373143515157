import {useLoaderData, useMatches} from '@remix-run/react';
import cn from 'classnames';
import {useState} from 'react';
import type {LinksFunction} from '@remix-run/server-runtime';
import type {LoaderFunction} from '@remix-run/cloudflare';
import {json, redirect} from '@remix-run/cloudflare';

import HeaderSection from '@/components/careers/section/HeaderSection/HeaderSection';
import {CommerceBanner} from '@/components/careers/section/CommerceBanner/CommerceBanner';
import {WhatWeDo} from '@/components/careers/section/WhatWeDo/WhatWeDo';
import {WhereWeHire} from '@/components/careers/section/WhereWeHire/WhereWeHire';
import {Footer} from '@/components/careers/section/Footer/Footer';
import {OurMission} from '@/components/careers/section/OurMission/OurMission';
import {GetInTouch} from '@/components/careers/section/GetInTouch/GetInTouch';
import {FooterVip} from '@/components/careers/section/FooterVip/FooterVip';
import {WhoWeHire} from '@/components/careers/section/WhoWeHire/WhoWeHire';
import {BeingExtraordinary} from '@/components/careers/section/BeingExtraordinary/BeingExtraordinary';
import {Theme} from '@/enums';
import {NavOptions} from '@/components/careers/shared/NavToggle/NavToggle';
import {isVIPPage} from '@/components/careers/utils/VIP';
import Wrapper from '@/components/shared/Page/Wrapper';
import logger from '@/utils/server/logger.server';
import {loadRequestData} from '@/utils/server/request.server';
import careersStylesheet from '@/stylesheets/careers/careers.css?url';
import AtsApi from '@/components/careers/services/AtsApi.server';
import type {
  Job,
  JobPosting,
  JobPostingListResponse,
  JobPostingWithJob,
  LocationsResponse,
} from '@/components/careers/types/ashby';
import JobPostings from '@/components/careers/section/JobPostings/JobPostings';
import {kvGetWithFallback} from '@/components/careers/utils/JobUtil';
import {parameterize} from '@/components/careers/utils/StringUtil';

export const handle = {
  theme: Theme.Careers,
  parentTheme: Theme.BrochureV2,
  shareImage:
    'https://cdn.shopify.com/b/shopify-brochure2-assets/110574c7abe7b5e5854539a5e8d5e26b.gif',
  metadata: {
    pageCategory: 'Careers',
    pageGroup: 'talent',
    pagePath: '/careers',
  },
};

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: careersStylesheet}];
};

const ashbyJIDRedirectPath = async (
  requestUrl: string,
  jobPostings: JobPosting[],
  atsApi: AtsApi,
): Promise<string | null> => {
  const url = new URL(requestUrl);
  const ashbyJID = url.searchParams.get('ashby_jid');

  if (ashbyJID) {
    const jobPostingRedirect =
      jobPostings.find((jobPosting) => jobPosting.id === ashbyJID) ||
      (await atsApi.getJobPosting(ashbyJID)).results;
    if (jobPostingRedirect) {
      return `/careers/${parameterize(jobPostingRedirect.title || '')}_${
        jobPostingRedirect.id
      }`;
    }
  }

  return null;
};

export const loader: LoaderFunction = async (args) => {
  const requestData = await loadRequestData(args);
  const kvStorage = args.context.storage;
  let jobPostingsWithJobs: JobPostingWithJob[] = [];

  const atsApi: AtsApi = new AtsApi(args.context.secrets.SECRET_ASHBY_API);

  const jobsList: Job[] = await kvGetWithFallback(
    kvStorage,
    'careers/jobs-list',
    async () => {
      const allJobs = await atsApi.getAllJobs();
      return allJobs;
    },
  );

  const jobPostingsListResponse: JobPostingListResponse =
    await atsApi.getAllJobPostings();
  if (!jobPostingsListResponse.errors) {
    const redirectPath = await ashbyJIDRedirectPath(
      args.request.url,
      jobPostingsListResponse.results,
      atsApi,
    );
    if (redirectPath) {
      return redirect(redirectPath);
    }

    jobPostingsWithJobs = jobPostingsListResponse.results.map((jobPosting) => {
      const job = jobsList.find((jobItem) => jobPosting.jobId === jobItem.id);

      return {
        jobPosting: jobPosting,
        job: job,
      };
    });
  }

  const locations = await kvGetWithFallback(
    kvStorage,
    'careers/locations',
    async () => {
      const atsLocations: LocationsResponse = await atsApi.getLocations();
      if (!atsLocations.errors) {
        return atsLocations.results;
      }
    },
  );

  if (requestData.handle) {
    return json({
      ...requestData,
      jobPostingsWithJobs: jobPostingsWithJobs,
      atsLocations: locations,
    });
  } else {
    logger.warn(`No data returned for key: ${requestData.canonicalUrl}`);
    throw json({}, {status: 404});
  }
};

export default function Careers() {
  const matches = useMatches();
  const data = useLoaderData<any>() ?? {};
  const {pathname} = matches[matches.length - 1];
  const [isVip, setIsVip] = useState(isVIPPage(pathname));
  const pageBeforeBase =
    'before:w-full before:h-full before:fixed before:top-0 before:left-0';
  const pageBaseHidden =
    'before:invisible before:opacity-0 before:bg-black/60 before:z-[999]';
  const pageTransition =
    'before:[transition:visibility_.4s_linear,opacity_.4s_cubic-bezier(0.4,0,0.2,1)]';

  const [modalOpen, setModalOpen] = useState(false);
  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  const Sparkle = () => (
    <div className="relative z-10">
      <img
        className="absolute top-0 right-0 pointer-events-none -translate-x-1/2 md:-translate-x-full -translate-y-1/3 scale-75 md:scale-100"
        sizes="100vw"
        src="https://cdn.shopify.com/b/shopify-brochure2-assets/d8c90bebd0873beb0b01d17080ec225f.svg"
        alt=""
      />
    </div>
  );

  return (
    <Wrapper data={data}>
      <div
        className={cn(
          'max-w-[1600px] mx-auto',
          pageBeforeBase,
          pageBaseHidden,
          pageTransition,
        )}
      >
        <main className={cn({hidden: modalOpen})}>
          <HeaderSection
            currentSection={isVip ? NavOptions.Vip : NavOptions.Role}
            setIsVip={setIsVip}
          />
          <div className="relative z-10 bg-white">
            <CommerceBanner isVip={isVip} />
            {!isVip ? (
              <>
                <WhatWeDo />
                <Sparkle />
                <WhereWeHire />
                <WhoWeHire />
                <JobPostings
                  atsLocations={data.atsLocations}
                  jobPostingsWithJobs={data.jobPostingsWithJobs}
                  setModalOpen={setModalOpen}
                />
                <Footer />
              </>
            ) : (
              <>
                <OurMission />
                <Sparkle />
                <BeingExtraordinary />
                <GetInTouch />
                <FooterVip />
              </>
            )}
          </div>
        </main>
        <div
          id="Modal"
          className={cn(
            {
              hidden: !modalOpen,
              'min-h-screen': modalOpen,
            },
            'p-9',
          )}
        >
          <div className="text-right mb-5">
            <button
              type="button"
              onClick={modalCloseHandler}
              className="bg-close-icon bg-no-repeat bg-center bg-[length:40%] w-5 h-5 p-5"
            >
              <span className="sr-only">Close</span>
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
