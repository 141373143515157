import type {ImgHTMLAttributes} from 'react';

export default function CareersSearchClear(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/a66935f4f5be6dd2ad6e0f83260b4650.svg"
      alt="Clear"
      {...props}
    />
  );
}
