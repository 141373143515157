import {useTranslations} from '@/components/shared/Page/I18N';

import Section from '../../shared/Section/Section';

interface Information {
  title: string;
  content: string;
}

export function OurMission() {
  const {t} = useTranslations();
  const informations: Information[] = t('ourMission.infos');
  return (
    <Section>
      <div className="grid lg:grid-cols-3 gap-10">
        {typeof informations === 'object' &&
          informations.map(({title, content}) => (
            <div key={title} className="border-t-2 border-t-black">
              <h3 className="text-xl md:text-[1.625rem] font-bold py-6">
                {title}
              </h3>
              <p className="text-base md:text-lg">{content}</p>
            </div>
          ))}
      </div>
    </Section>
  );
}
