import type {
  JobPosting,
  FieldSubmission,
  Location,
  JobPostingWithJob,
  Job,
} from '@/components/careers/types/ashby';
import {getRegionByCountryCode} from '@/components/careers/utils/LocationUtil';
import type {Storage} from '@/storage';

const JOB_POSTING_ID_FIELD_NAME = 'jobPostingId';

export const getJobDiscipline = (
  jobPostingWithJob: JobPostingWithJob,
): string | undefined => {
  const customFields = jobPostingWithJob.job?.customFields;
  let discipline;
  if (customFields) {
    discipline = customFields.find(
      (customField) => customField.title === 'Discipline',
    )?.value as string;
  }

  return discipline ? discipline : jobPostingWithJob.jobPosting?.teamName;
};

export const orderJobDisciplines = (disciplines: string[]) => {
  const priorities: {[key: string]: number} = {
    Engineering: 1,
    'Data Insights': 2,
    UX: 3,
    Product: 4,
  };

  const sortedDisciplines = disciplines.sort((a, b) => {
    const priorityA = priorities[a] || disciplines.length + 1;
    const priorityB = priorities[b] || disciplines.length + 1;

    return priorityA - priorityB;
  });

  return sortedDisciplines;
};

export const isJobEvergreen = (job: Job | undefined): boolean => {
  return !!job?.customFields?.find(
    (customField) =>
      customField.title === 'Job Type' && customField.value === 'evergreen',
  );
};

export const filterJobPostingsByKeywords = (
  jobPostingsWithJobs: JobPostingWithJob[],
  keywords: string,
) => {
  return jobPostingsWithJobs.filter((jobPostingWithJob) => {
    if (!jobPostingWithJob) return false;
    const jobTitle = jobPostingWithJob.jobPosting?.title.toLowerCase();
    return jobTitle?.includes(keywords.toLowerCase());
  });
};

export const filterJobPostingsByLocations = (
  jobPostingsWithJobs: JobPostingWithJob[],
  locations: string[],
) => {
  if (locations.length === 0) {
    return jobPostingsWithJobs;
  }

  return jobPostingsWithJobs.filter((jobPostingWithJob) => {
    if (!jobPostingWithJob) return false;
    const regions = locations.map((location) =>
      getRegionByCountryCode(location),
    );
    return regions.includes(jobPostingWithJob.jobPosting?.locationName);
  });
};

export const buildJobApplicationPayload = (
  formData: FormData,
  utmData: {[key: string]: string},
): FormData => {
  const apiFormData = new FormData();
  let fieldSubmissions: FieldSubmission[] = [];
  for (let [key, value] of formData.entries()) {
    if (key === JOB_POSTING_ID_FIELD_NAME) {
      apiFormData.append('jobPostingId', value);
    } else if (key.indexOf('[]') !== -1) {
      // Cleanup
      const multiValues = formData.getAll(key).map((selectedValue) => {
        return selectedValue;
      });
      fieldSubmissions.push({
        path: key.replace('[]', ''),
        value: Array.from(multiValues),
      });
    } else if (value === '1') {
      fieldSubmissions.push({
        path: key,
        value: true,
      });
    } else if (key.indexOf('_number') !== -1) {
      fieldSubmissions.push({
        path: key.replace('_number', ''),
        value: parseInt(value as string),
      });
    } else if (key.indexOf('location_value') !== -1) {
      let locationValue;
      const locationKey = key.replace('_location_value', '');
      if (value) {
        locationValue = JSON.parse(value as string);
      } else {
        const originalString = formData.get(`${locationKey}_label`) as string;
        if (originalString) {
          const parts = originalString
            .split(',')
            .map((part) => part.trim())
            .filter((part) => part !== '');

          locationValue = {
            country: parts.pop(),
            region: parts.pop(),
            city: parts.pop(),
          };
        }
      }

      fieldSubmissions.push({
        path: locationKey,
        value: locationValue,
      });
    } else if (value instanceof File) {
      if (value.size > 0) {
        const fileFieldName = `${key}_file`;
        apiFormData.append(fileFieldName, value);
        fieldSubmissions.push({
          path: key,
          value: fileFieldName,
        });
      }
    } else if (key.indexOf('_label') === -1) {
      fieldSubmissions.push({
        path: key,
        value,
      });
    }
  }

  apiFormData.append('applicationForm', JSON.stringify({fieldSubmissions}));
  apiFormData.append('utmData', JSON.stringify(utmData));
  return apiFormData;
};

export const getJobPostingIdFromURLSegment = (
  segment: string | undefined,
): string | undefined => {
  return segment?.split('_')[1];
};

export const getJobPostingLocation = (
  jobPosting: JobPosting | undefined,
  atsLocations?: Location[],
): string | undefined => {
  if (!jobPosting) {
    return;
  }

  const locationPrefix = 'Remote - ';

  if (atsLocations) {
    const location: Location | undefined = atsLocations.find((locationItem) => {
      return locationItem.id === jobPosting.locationIds.primaryLocationId;
    });

    if (location) {
      return location.isRemote
        ? `${locationPrefix}${location.name}`
        : location.name;
    }
  }

  const remotePrefix = jobPosting.isRemote === true ? locationPrefix : '';
  return `${remotePrefix}${jobPosting.locationName}`;
};

export const kvGetWithFallback = async (
  storage: Storage,
  cacheKey: string,
  fallback: Function,
) => {
  const ttlSeconds = 3600;
  const cachedData: string | null = await storage.getKey(cacheKey, {
    cacheTtl: ttlSeconds,
  });

  let data;
  if (cachedData) {
    data = JSON.parse(cachedData);
  } else {
    data = await fallback();
    if (data) {
      storage.setKey(cacheKey, JSON.stringify(data), {
        expirationTtl: ttlSeconds,
      });
    }
  }

  return data;
};
