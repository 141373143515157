import {DivHtml} from '@/components/shared/HtmlElements';
import {useTranslations} from '@/components/shared/Page/I18N';

import Section from '../../shared/Section/Section';

import {CountryList} from './components/CountryList';
import {Globe} from './components/Globe';

export function WhereWeHire() {
  const {t} = useTranslations();

  return (
    <Section
      eyebrow={t('whereWeHire.title')}
      heading={t('whereWeHire.disclaimer')}
      childPosition="adjacent"
      background="black"
      supportingContent={<Globe />}
    >
      <>
        <DivHtml>{t('whereWeHire.content')}</DivHtml>
        <div>
          <CountryList />
        </div>
      </>
    </Section>
  );
}
