import type {ImgHTMLAttributes} from 'react';

export default function CareersLogoBlack(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/300e9fa74de3ba3d5f0eb67d6face405.svg"
      alt="Shopify Careers"
      {...props}
    />
  );
}
