import {useTranslations} from '@/components/shared/Page/I18N';
import Section from '@/components/careers/shared/Section/Section';

export function GetInTouch() {
  const {t} = useTranslations();

  return (
    <Section
      heading={t('getInTouch.title')}
      headingStyle="large"
      childPosition="adjacent"
      background="vip-gradient"
    >
      <>
        <div className="text-xl lg:text-2xl font-medium lg:font-bold">
          {t('getInTouch.disclaimer')}
        </div>
        <div>
          <a
            className="text-lg lg:text-base inline-block mt-6 text-white text-center font-bold rounded-full py-3 px-7 lg:py-5 bg-black"
            href="/careers/vip-access/apply"
          >
            {t('getInTouch.submitLabel')}
          </a>
        </div>
      </>
    </Section>
  );
}
