import cn from 'classnames';

import {useTranslations} from '@/components/shared/Page/I18N';

import TopHeader from '../../shared/Header/TopHeader';
import ThingsToKnow from '../ThingsToknow/ThingsToKnow';
import VipAccess from '../VipAccess/VipAccess';
import {NavOptions} from '../../shared/NavToggle/NavToggle';

interface HeaderSectionProps {
  currentSection: NavOptions;
  setIsVip: (isVip: boolean) => void;
}

export default function HeaderSection({
  currentSection,
  setIsVip,
}: HeaderSectionProps) {
  const {t} = useTranslations();
  const updateSectionHandler = (option: NavOptions) => {
    setIsVip(option === NavOptions.Vip);
    if (option) {
      history.pushState(
        null,
        '',
        option === NavOptions.Role ? '/careers' : '/careers/vip-access',
      );
    }
  };

  const backgroundBeforeClasses =
    currentSection === NavOptions.Vip
      ? 'before:opacity-100'
      : 'before:opacity-0';

  return (
    <section
      className={cn(
        'relative before:block before:transition-opacity before:bg-cover before:bg-vip-gradient-hero before:absolute before:w-full before:h-full before:t-0 before:l-0',
        backgroundBeforeClasses,
      )}
    >
      <header className="relative min-h-screen z-30">
        <div className="py-10 lg:py-16 px-8 lg:px-24">
          <TopHeader
            currentSection={currentSection}
            onNavToggle={(option: NavOptions) => updateSectionHandler(option)}
          />
        </div>
        <div className=" lg:px-24 lg:py-32">
          <div className="w-full h-full lg:mt-[-75px] bg-white">
            <div
              className="border-black border-t lg:border-x font-courier text-2xl overflow-hidden whitespace-nowrap p-3"
              aria-label={t('headerSection.readme')}
            >
              {new Array(10).fill(0).map((_value, index) => {
                return (
                  <span
                    key={`${currentSection}_header_${index}`}
                    className="animate-scroll inline-block pr-28"
                    aria-hidden={true}
                  >
                    <span className="align-middle">👀</span>{' '}
                    {t('headerSection.readme')}
                  </span>
                );
              })}
            </div>
            {currentSection === NavOptions.Role ? (
              <ThingsToKnow />
            ) : (
              <VipAccess />
            )}
          </div>
        </div>
      </header>
    </section>
  );
}
