import {DivHtml} from '@/components/shared/HtmlElements';
import {useTranslations} from '@/components/shared/Page/I18N';

import Section from '../../shared/Section/Section';

export function WhatWeDo() {
  const {t} = useTranslations();

  return (
    <Section
      eyebrow={t('whatWeDo.title')}
      heading={t('whatWeDo.disclaimer')}
      childPosition="under"
      bodySize="small"
    >
      <>
        <DivHtml>{t('whatWeDo.content')}</DivHtml>
        <iframe
          className="aspect-video w-full max-w-[816px] mt-14"
          src="https://www.youtube.com/embed/rgZU5pDf6mw?rel=0&color=white"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        <DivHtml>{t('whatWeDo.content2')}</DivHtml>
      </>
    </Section>
  );
}
