import type {ImgHTMLAttributes} from 'react';

export default function CareersTwitterIcon(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/55722149dfa25351c3cb186501e445d1.svg"
      alt="Twitter"
      {...props}
    />
  );
}
