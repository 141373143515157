import type {ImgHTMLAttributes} from 'react';

export default function CareersLocationPin(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/cbd0fdfab7e031624e393292125a9909.svg"
      alt=""
      {...props}
    />
  );
}
