import {useEffect, useRef, useState} from 'react';

import type {
  JobPostingWithJob,
  Location,
} from '@/components/careers/types/ashby';
import JobPostingsByDiscipline from '@/components/careers/section/JobPostings/components/JobPostingsByDiscipline';
import JobPostingsFilters from '@/components/careers/section/JobPostings/components/JobPostingsFilters';
import {useTranslations} from '@/hooks/useTranslations';
import {
  filterJobPostingsByKeywords,
  filterJobPostingsByLocations,
} from '@/components/careers/utils/JobUtil';

interface JobPostingsProps {
  atsLocations: Location[];
  jobPostingsWithJobs: JobPostingWithJob[];
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function JobPostings({
  atsLocations,
  jobPostingsWithJobs,
  setModalOpen,
}: JobPostingsProps) {
  const {t} = useTranslations();
  const filterFormRef = useRef<HTMLFormElement>(null);
  const [filteredJobPostings, setFilteredJobPostings] =
    useState<JobPostingWithJob[]>(jobPostingsWithJobs);
  const [locationFilter, setLocationFilter] = useState<string[]>([]);
  const [keywordFilter, setKeywordFilter] = useState<string>('');

  useEffect(() => {
    const currentURL = new URL(window.location.href);
    const searchParams = currentURL.searchParams;
    const keyword = searchParams.get('keyword');
    const locations = searchParams.getAll('location[]');

    setKeywordFilter(keyword || '');
    setLocationFilter(locations || []);
  }, []);

  useEffect(() => {
    let filteredJobPostingsResult = filterJobPostingsByKeywords(
      jobPostingsWithJobs,
      keywordFilter,
    );

    filteredJobPostingsResult = filterJobPostingsByLocations(
      filteredJobPostingsResult,
      locationFilter,
    );

    setFilteredJobPostings(filteredJobPostingsResult);
  }, [jobPostingsWithJobs, keywordFilter, locationFilter]);

  useEffect(() => {
    const currentURL = new URL(window.location.href);
    const searchParams = currentURL.searchParams;
    if (keywordFilter) {
      searchParams.set('keyword', keywordFilter);
    } else {
      searchParams.delete('keyword');
    }

    searchParams.delete('location[]');

    locationFilter.forEach((location) => {
      searchParams.append('location[]', location);
    });

    window.history.pushState(
      {path: currentURL.toString()},
      '',
      currentURL.toString(),
    );
  }, [keywordFilter, locationFilter]);

  return (
    <section
      className="relative py-10 px-8 lg:px-40 lg:py-32 before:block before:content-joblist-divide before:absolute before:-top-5 before:right-0 lg:before:left-1/3 before:-translate-y-1/3 before:scale-[.4] lg:before:scale-100"
      id="Jobs"
    >
      <div className="mb-6 lg:mb-20">
        <h2 className="uppercase text-xl text-teal font-bold mb-4">
          {t('jobList.searchTitle')}
        </h2>
        <div className="lg:grid lg:grid-cols-12 gap-10">
          <p className="relative col-span-4 text-2xl lg:text-4xl font-extrabold">
            {t('jobList.findRole')}
          </p>
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-12 gap-10 ">
        <div className="col-span-4 lg:border-r border-gray-200 lg:pr-8">
          <JobPostingsFilters
            formRef={filterFormRef}
            keywordFilter={keywordFilter}
            setKeywordFilter={setKeywordFilter}
            locationFilter={locationFilter}
            setLocatonFilter={setLocationFilter}
            jobPostingsWithJobs={jobPostingsWithJobs}
            setModalOpen={setModalOpen}
          />
        </div>
        <div className="col-span-8">
          <JobPostingsByDiscipline
            jobPostingsWithJobs={filteredJobPostings}
            atsLocations={atsLocations}
          />
        </div>
      </div>
    </section>
  );
}
