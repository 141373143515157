import {DivHtml} from '@/components/shared/HtmlElements';
import {useTranslations} from '@/components/shared/Page/I18N';

import Section from '../../shared/Section/Section';

export function WhoWeHire() {
  const {t} = useTranslations();

  const supportingContent = (
    <>
      <iframe
        className="aspect-video w-full max-w-[816px] mt-14"
        src="https://www.youtube.com/embed/wQJia5PQj_w?rel=0&color=white"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      <div className="grid lg:grid-cols-3 gap-10 mt-16">
        <div className="border-t border-black border-solid pt-6">
          <h3 className="text-[1.375rem] leading-[1.625rem] font-bold pb-4">
            <DivHtml>{t('whoWeHire.infos.careDeeply.title')}</DivHtml>
          </h3>
          <p className="md:text-lg">
            {t('whoWeHire.infos.careDeeply.content')}
          </p>
        </div>
        <div className="border-t border-black border-solid pt-6">
          <h3 className="text-[1.375rem] leading-[1.625rem] font-bold pb-4">
            <DivHtml>{t('whoWeHire.infos.thriveNotSurvive.title')}</DivHtml>
          </h3>
          <p className="md:text-lg">
            {t('whoWeHire.infos.thriveNotSurvive.content')}
          </p>
        </div>
        <div className="border-t border-black border-solid pt-6">
          <h3 className="text-[1.375rem] leading-[1.625rem] font-bold pb-4">
            <DivHtml>{t('whoWeHire.infos.constantLearning.title')}</DivHtml>
          </h3>
          <p className="md:text-lg">
            {t('whoWeHire.infos.constantLearning.content')}
          </p>
        </div>
      </div>
      <p className="mt-12">Shopify is a platform company singularly focused on creating a world with more entrepreneurs, which we believe causes positive societal effects. While we respect everyone’s right to political debate and engage in personal activism on their own time, we stick to the time-honored principle of leaving politics out of the workplace and off our internal communications systems. As a company we don’t weigh in publicly unless directly related to our mission - the Shopify ecosystem is made strong as a result of the diversity of backgrounds, views, and experiences of our merchants and colleagues. To work at Shopify, you must be comfortable holding this tension.</p>
    </>
  );

  return (
    <Section
      eyebrow={t('whoWeHire.title')}
      heading={t('whoWeHire.disclaimer')}
      supportingContent={supportingContent}
      childPosition="adjacent"
    >
      <p>{t('whoWeHire.content')}</p>
    </Section>
  );
}
