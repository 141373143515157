import type {ImgHTMLAttributes} from 'react';

export default function CareersLinkedinIcon(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/6f1c64e81f3e6f42d517766ac230c8b8.svg"
      alt="LinkedIn"
      {...props}
    />
  );
}
