import cn from 'classnames';
import {useRef} from 'react';

import {useTranslations} from '@/components/shared/Page/I18N';

import {useOnScreen} from '../../hooks/useOnScreen';

interface Props {
  isVip: boolean;
}

export const CommerceBanner = ({isVip}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const {t} = useTranslations();

  return (
    <section
      className={cn('px-8 lg:px-40', {
        'bg-commerce-gradient bg-cover': !isVip,
        'bg-black text-white': isVip,
      })}
      id="mission"
    >
      <h2 className="pt-28 pb-36 text-6xl font-black leading-[.9em] lg:text-[110px] max-w-[1210px]">
        {t('commerceBanner.makeCommerBetterFor')
          .split(' ')
          .map((word: string) => {
            return <div key={word}>{word}</div>;
          })}
        <div>
          <span
            ref={ref}
            className={cn(
              'relative pb-2 after:absolute after:bottom-0 after:left-0 after:h-1.5 after:transition-all after:duration-1000 after:delay-75',
              {
                'after:bg-black': !isVip,
                'after:bg-[linear-gradient(97.28deg,#dfb2f7_4.51%,#6dd6b5_92.56%)]':
                  isVip,
                'after:bg-[length:100%_6px] after:bg-no-repeat after:bg-bottom':
                  isVip,
                'after:w-0': !isVisible,
                'after:w-full': isVisible,
              },
            )}
          >
            {t('commerceBanner.everyone', '')}
          </span>
        </div>
      </h2>
    </section>
  );
};
