import {DivHtml} from '@/components/shared/HtmlElements';
import {useTranslations} from '@/components/shared/Page/I18N';

export default function VipAccess() {
  const {t} = useTranslations();

  return (
    <div className="p-[6%] border-black border-y lg:border w-full">
      <h2 className="font-courier text-3xl md:text-5xl font-extrabold mb-6">
        {t('headerSection.vipAccess.title')}
      </h2>
      <div className="grid gap-6 max-w-3xl">
        <div className="text-2xl">
          <DivHtml className="text-lg md:leading-6">
            {t('headerSection.vipAccess.contentHTML')}
          </DivHtml>
        </div>
        <div>
          <a
            href="#mission"
            className="block font-bold w-28 text-2xl text-center min-h-24 mt-5 after:bg-careers-arrow after:bg-no-repeat after:bg-bottom after:block after:h-11 after:w-7 after:animate-arrowpoint after:mt-2 after:mx-auto"
          >
            {t('headerSection.vipAccess.action')}
          </a>
        </div>
      </div>
    </div>
  );
}
