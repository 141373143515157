import {locations} from '@/components/careers/data/locations';

const getAllCountries = () => {
  const countriesList = locations
    .map((location) => {
      return location.countries;
    })
    .flat();

  return countriesList.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getCountriesWithHiringEntities = () => {
  return getAllCountries().filter((country) => country.hiringEntity);
};

export const getRegionByCountryCode = (countryCode: string) => {
  const location = locations.find((locationItem) => {
    return locationItem.countries.some(
      (country) =>
        country.alpha2Code === countryCode ||
        country.alpha3Code === countryCode,
    );
  });

  return location?.region;
};

export const getCountriesByRegion = (region: string) => {
  return locations.find((location) => location.region === region)?.countries;
};
