import cn from 'classnames';

interface SectionProps {
  eyebrow?: string;
  heading?: string;
  headingStyle?: 'large';
  supportingContent?: JSX.Element;
  beforeContent?: JSX.Element;
  children?: JSX.Element;
  background?:
    | 'white'
    | 'black'
    | 'light-blue'
    | 'gradient-green'
    | 'vip-gradient';
  childPosition?: 'adjacent' | 'under';
  type?: 'narrow';
  border?: boolean | 'no-top';
  bodySize?: 'small';
}

export default function Section(props: SectionProps) {
  const BeforeContent = () => {
    if (props.beforeContent) {
      return props.beforeContent;
    }

    return null;
  };

  const Eyebrow = () => {
    if (props.eyebrow) {
      return (
        <h2
          className={cn('text-xl leading-none uppercase font-semibold mb-4', {
            'text-teal': props.background !== 'black',
            'text-light-teal': props.background === 'black',
          })}
        >
          {props.eyebrow}
        </h2>
      );
    }

    return null;
  };

  const Heading = () => {
    if (props.eyebrow && props.heading) {
      return (
        <p
          className={cn('font-extrabold', {
            'text-white': props.background === 'black',
            'text-2xl mb-6': !props.type,
            'md:text-4xl': !props.type && !props.headingStyle,
            'md:text-5xl': !props.type && props.headingStyle,
            'text-[1.625rem] mb-1': props.type === 'narrow',
          })}
        >
          {props.heading}
        </p>
      );
    } else if (props.heading) {
      return (
        <h2
          className={cn('font-extrabold', {
            'text-white': props.background === 'black',
            'text-2xl mb-6': !props.type,
            'md:text-4xl': !props.type && !props.headingStyle,
            'md:text-5xl': !props.type && props.headingStyle,
            'text-[1.625rem] mb-1': props.type === 'narrow',
          })}
        >
          {props.heading}
        </h2>
      );
    }

    return null;
  };
  return (
    <section
      className={cn('overflow-hidden relative', {
        'bg-light-blue': props.background === 'light-blue',
        'bg-black': props.background === 'black',
        'bg-[linear-gradient(126.51deg,#5fd5b6_32.36%,#4bfe85_100%)]':
          props.background === 'gradient-green',
        'py-[16%] md:py-[8%] px-8 lg:px-40': !props.type,
        'px-[6%] py-11 lg:px-28': props.type === 'narrow',
        'border-t border-b border-black border-solid lg:border-l lg:border-r':
          props.border === true,
        'border-b border-black border-solid lg:border-l lg:border-r':
          props.border === 'no-top',
        'bg-vip-gradient-footer bg-cover': props.background === 'vip-gradient',
      })}
    >
      <BeforeContent />
      <div>
        <Eyebrow />
        <div
          className={cn({
            'grid md:grid-cols-[39%_54%] gap-x-[7%]':
              props.childPosition === 'adjacent',
          })}
        >
          <Heading />
          <div
            className={cn(
              {
                'md:text-[1.375rem] md:leading-[1.9rem]':
                  props.childPosition === 'under' &&
                  !props.type &&
                  props.bodySize !== 'small',
                'md:text-lg':
                  props.childPosition === 'adjacent' || props.type === 'narrow',
                'max-w-[816px]': props.childPosition === 'under' && !props.type,
                'max-w-[676px]': props.childPosition === 'under' && props.type,
                'text-white': props.background === 'black',
              },
              'text-base space-y-4',
            )}
          >
            {props.children}
          </div>
        </div>
      </div>
      <div>{props.supportingContent}</div>
    </section>
  );
}
