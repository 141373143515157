import type {ImgHTMLAttributes} from 'react';

export default function CareersFacebookIcon(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/b664fc18c79f01b82bfd47448231ff34.svg"
      alt="Facebook"
      {...props}
    />
  );
}
