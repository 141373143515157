import {useState} from 'react';
import cn from 'classnames';

import {useTranslations} from '@/components/shared/Page/I18N';

export enum NavOptions {
  Role = 'Role',
  Vip = 'Vip',
}

type NavToType = NavOptions.Role | NavOptions.Vip;

interface NavToggleProps {
  onNavToggle: (option: NavOptions) => void;
  currentSection: NavOptions;
}

export default function NavToggle({
  currentSection,
  onNavToggle = () => {},
}: NavToggleProps) {
  const [currentNav, setCurrentNav] = useState<NavToType>(currentSection);
  const {t} = useTranslations();

  const navToggleHandler = (option: NavOptions) => {
    onNavToggle(option);
    setCurrentNav(option);
  };

  const classNavBase =
    'relative self-center rounded-full bg-black border border-black';
  const classNavDimension = 'z-20 h-10 lg:w-72 lg:h-12 ';
  const classButton =
    'py-1 my-1 ml-1 w-1/2 cursor-pointer select-none focus:outline-none font-semibold text-sm';

  return (
    <>
      <nav className={cn(classNavBase, classNavDimension)}>
        <div
          id="slider"
          className={cn(
            'absolute inset-y-0 w-1/2 h-full p-[1px] transition-transform',
            {
              'translate-x-0': currentNav === NavOptions.Role,
              'translate-x-full': currentNav === NavOptions.Vip,
            },
          )}
        >
          <div
            className={cn(
              'w-full h-full bg-[linear-gradient(94.99deg,#93E2BC_0%,#F3E989_94.16%)] rounded-full',
            )}
          ></div>
        </div>
        <div className="relative flex w-full h-full">
          <button
            tabIndex={0}
            className={cn(classButton, {
              active: currentNav === NavOptions.Role,
              'text--black': currentNav === NavOptions.Role,
              'text-white': currentNav !== NavOptions.Role,
            })}
            onClick={() => {
              navToggleHandler(NavOptions.Role);
            }}
          >
            {t('headerSection.findYourRole')}
          </button>
          <button
            tabIndex={1}
            className={cn(classButton, {
              active: currentNav === NavOptions.Vip,
              'text-black': currentNav === NavOptions.Vip,
              'text-white': currentNav !== NavOptions.Vip,
            })}
            onClick={() => {
              navToggleHandler(NavOptions.Vip);
            }}
          >
            {t('headerSection.getVipAccess')}
          </button>
        </div>
      </nav>
    </>
  );
}
