import cn from 'classnames';
import {useRef} from 'react';

import {CareersLocationPin} from '@/components/careers/assets';

import {useOnScreen} from '../../../hooks/useOnScreen';

export function Globe() {
  const iconGlobeClass =
    'absolute top-0 left-0 w-3 transition-all duration-1000';
  const iconGlobeClassHide = 'opacity-0 translate-y-[-50px]';
  const iconGlobeClassShow = 'opacity-100 translate-y-0';

  const ref = useRef<HTMLImageElement>(null);
  const isGlobeVisible = useOnScreen(ref);

  const iconGlobeClasses = {
    [iconGlobeClassHide]: !isGlobeVisible,
    [iconGlobeClassShow]: isGlobeVisible,
  };

  const globePinClasses = [
    'mt-[32%] ml-[20%] delay-[0.1s]',
    'mt-[38%] ml-[24%] scale-[1.3] delay-[0.3s]',
    'mt-[31%] ml-[31%] scale-[1.3] delay-[0.8s]',
    'mt-[28%] ml-[41%] scale-[0.6] delay-[0.4s]',
    'mt-[35%] ml-[74%] scale-[1.4] delay-[0.7s]',
    'mt-[28%] ml-[76%] scale-[0.7] delay-[0.4s]',
    'mt-[25%] ml-[83%] scale-[0.7] delay-[0.5s]',
    'mt-[23%] ml-[75%] scale-[0.9] delay-[0.6s]',
    'mt-[23%] ml-[89%] scale-[1.2] delay-[0.9s]',
  ];

  return (
    <div className="hidden relative origin-top-right scale-[1.22] w-[44%] mt-[-24%] mb-[-18%] ml-[-13%] lg:block">
      <span className="relative max-[705px]">
        <span className="pb-[99.8582%]">
          <img
            ref={ref}
            alt=""
            className=" lazyloaded"
            data-sizes="100vw"
            data-srcset="https://cdn.shopify.com/shopifycloud/brochure/assets/home/globe-small-cc4ff2810424a8d2cec05c8c8e19ec05439ce0e1dacd298f74b8763e3d05becc.png 1x, https://cdn.shopify.com/shopifycloud/brochure/assets/home/globe-large-d800372fa508497051834e5ec9e6b5b29ed37463f42791396671e0f374d44f29.png?quality=50 2x"
            sizes="100vw"
            srcSet="https://cdn.shopify.com/shopifycloud/brochure/assets/home/globe-small-cc4ff2810424a8d2cec05c8c8e19ec05439ce0e1dacd298f74b8763e3d05becc.png 1x, https://cdn.shopify.com/shopifycloud/brochure/assets/home/globe-large-d800372fa508497051834e5ec9e6b5b29ed37463f42791396671e0f374d44f29.png?quality=50 2x"
          />
        </span>
      </span>
      {globePinClasses.map((globePinClass, index) => (
        <CareersLocationPin
          key={`pin-${index}`}
          className={cn(iconGlobeClass, globePinClass, iconGlobeClasses)}
        />
      ))}
    </div>
  );
}
