import type {ImgHTMLAttributes} from 'react';

export default function CareersYoutubeIcon(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/f15b505651af9f3dd72de552b92e5d2c.svg"
      alt="Youtube"
      {...props}
    />
  );
}
