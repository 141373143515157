import {useSharedTranslations} from '@/hooks/useTranslations';

export function FooterVip() {
  const {t} = useSharedTranslations('pages/careers/shared');
  return (
    <section className="bg-black text-white pt-[6%]">
      <div
        className="font-courier text-2xl overflow-hidden whitespace-nowrap p-3"
        aria-label={t('Footer.vipAccess')}
      >
        {new Array(10).fill(0).map((_value, index) => {
          return (
            <span
              key={`footer_${index}`}
              className="animate-scroll inline-block pr-28"
              aria-hidden={true}
            >
              <span className="align-middle">✨</span> {t('Footer.vipAccess')}
            </span>
          );
        })}
      </div>
      <div>
        <nav className="py-32 text-center">
          <a
            className="vip-footer-link mr-4"
            data-event-page="careers-home"
            data-event-category="link"
            data-event-action="click-link-footer"
            data-event-label="terms"
            href="/legal/terms"
          >
            {t('Footer.terms')}
          </a>
          <a
            className="vip-footer-link ml-4"
            data-event-page="careers-home"
            data-event-category="link"
            data-event-action="click-link-footer"
            data-event-label="privacy"
            href="/legal/privacy"
          >
            {t('Footer.privacy')}
          </a>
        </nav>
      </div>
    </section>
  );
}
