import {useRef} from 'react';

import type {
  JobPostingWithJob,
  Location,
} from '@/components/careers/types/ashby';
import {
  getJobDiscipline,
  isJobEvergreen,
  orderJobDisciplines,
} from '@/components/careers/utils/JobUtil';
import JobPosting from '@/components/careers/section/JobPostings/components/JobPosting';

interface JobPostingsByDisciplineProps {
  atsLocations: Location[];
  jobPostingsWithJobs: JobPostingWithJob[];
}

interface JobPostingsByDiscipline {
  [key: string]: {
    evergreen: JobPostingWithJob[];
    open: JobPostingWithJob[];
  };
}

const organizeJobPostingsWithJobsByDiscipline = (
  jobPostingsWithJobs: JobPostingWithJob[],
): JobPostingsByDiscipline => {
  const jobPostingsByDiscipline: JobPostingsByDiscipline = {};

  jobPostingsWithJobs.forEach((jobPostingWithJob) => {
    if (!jobPostingWithJob.jobPosting) return;

    let discipline = getJobDiscipline(jobPostingWithJob) as string;

    if (!jobPostingsByDiscipline[discipline]) {
      jobPostingsByDiscipline[discipline] = {
        evergreen: [],
        open: [],
      };
    }

    if (isJobEvergreen(jobPostingWithJob.job)) {
      jobPostingsByDiscipline[discipline]['evergreen'].push(jobPostingWithJob);
    } else {
      jobPostingsByDiscipline[discipline]['open'].push(jobPostingWithJob);
    }
  });

  return jobPostingsByDiscipline;
};

export default function JobPostingsByDiscipline({
  atsLocations,
  jobPostingsWithJobs,
}: JobPostingsByDisciplineProps) {
  const jobPostingsByDiscipline: JobPostingsByDiscipline =
    organizeJobPostingsWithJobsByDiscipline(jobPostingsWithJobs);
  const orderedJobDisciplines = orderJobDisciplines(
    Object.keys(jobPostingsByDiscipline),
  );
  const titleRef = useRef<HTMLHeadingElement>(null);

  return (
    <div>
      {orderedJobDisciplines.map((discipline) => {
        return (
          <div className="mb-10" id={discipline} key={discipline}>
            <h3 className="text-2xl lg:text-4xl font-bold mb-6" ref={titleRef}>
              {discipline}
            </h3>
            {jobPostingsByDiscipline[discipline]['evergreen'].length > 0 && (
              <div className="mb-8 group">
                <div className="py-4 group-hover:shadow-xl border border-solid border-black rounded-3xl overflow-hidden relative bg-[url('https://cdn.shopify.com/b/shopify-brochure2-assets/5d0b1b722150b76aa304eb8a510ce3f8.jpg')] bg-cover">
                  {jobPostingsByDiscipline[discipline]['evergreen'].length >
                    0 && (
                    <h4 className="text-lg lg:text-2xl font-bold py-3 px-8">
                      We're always hiring for:
                    </h4>
                  )}
                  <div className="">
                    {jobPostingsByDiscipline[discipline]['evergreen'].map(
                      (jobPostingWithJob: JobPostingWithJob) => {
                        if (!jobPostingWithJob.jobPosting) {
                          return null;
                        }
                        return (
                          <JobPosting
                            key={jobPostingWithJob.jobPosting.id}
                            jobPosting={jobPostingWithJob.jobPosting}
                            atsLocations={atsLocations}
                            size="small"
                            customTitle={
                              jobPostingWithJob.jobPosting.title === 'UX'
                                ? 'Designers'
                                : jobPostingWithJob.jobPosting.title
                            }
                          />
                        );
                      },
                    )}
                  </div>

                  <img
                    src="https://cdn.shopify.com/b/shopify-brochure2-assets/d8c90bebd0873beb0b01d17080ec225f.svg"
                    alt=""
                    className="absolute top-5 right-5 w-[40px] z-10"
                  />
                </div>
              </div>
            )}
            {jobPostingsByDiscipline[discipline]['evergreen'].length > 0 &&
              jobPostingsByDiscipline[discipline]['open'].length > 0 && (
                <h5 className="text-lg lg:text-xl font-bold mb-4">
                  Other open roles:
                </h5>
              )}
            <div className="divide-y divide-solid divide-gray-200">
              {jobPostingsByDiscipline[discipline]['open'].map(
                (jobPostingWithJob: JobPostingWithJob) => {
                  if (!jobPostingWithJob.jobPosting) {
                    return null;
                  }
                  return (
                    <JobPosting
                      key={jobPostingWithJob.jobPosting.id}
                      jobPosting={jobPostingWithJob.jobPosting}
                      atsLocations={atsLocations}
                    />
                  );
                },
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
