import {useEffect, useState} from 'react';
import classNames from 'classnames';
import {utmParameters} from '@shopify/dux';

import {CareersLocationPin} from '@/components/careers/assets';
import type {
  JobPosting as JobPostingType,
  Location,
} from '@/components/careers/types/ashby';
import {getJobPostingLocation} from '@/components/careers/utils/JobUtil';
import {parameterize} from '@/components/careers/utils/StringUtil';

interface JobPostingProps {
  atsLocations?: Location[];
  jobPosting: JobPostingType;
  customTitle?: string;
  size?: 'small' | 'large';
}

export default function JobPosting({
  jobPosting,
  atsLocations,
  customTitle,
  size,
}: JobPostingProps) {
  const [jobPostingURL, setjobPostingURL] = useState('');
  useEffect(() => {
    if (!jobPosting) return;
    const utmSearchParams = new URLSearchParams(
      utmParameters(new URL(window.location.href)),
    );
    const urlFormat = `/careers/${parameterize(jobPosting.title || '')}_${
      jobPosting.id
    }?${utmSearchParams.toString()}`;
    setjobPostingURL(urlFormat);
  }, [jobPosting]);

  if (!jobPosting) return null;

  const LocationLabel = () => {
    if (!atsLocations) return null;

    const location: string | undefined = getJobPostingLocation(
      jobPosting,
      atsLocations,
    );

    if (location) {
      return (
        <div className="flex items-center gap-1">
          <CareersLocationPin width={12} height={12} />
          <span>{location}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={classNames(
        'relative block w-full bg-transparent z-10 before:absolute before:w-full before:h-full before:z-[-1] before:top-0 before:left-0 before:opacity-0 hover:before:opacity-100 before:transition-opacity',
        {
          'before:bg-[linear-gradient(121.46deg,rgba(238,255,154,0.4)_9.89%,rgba(183,239,189,0.4)_100%)] before:duration-500':
            size !== 'small',
        },
        {
          'before:bg-black hover:text-white before:duration-100':
            size === 'small',
        },
      )}
    >
      <a
        href={jobPostingURL}
        className={classNames(
          'cursor-pointer z-20 block group',
          {'px-8 py-6': !size || size === 'large'},
          {'px-14 py-4': size === 'small'},
        )}
      >
        <h3
          className={classNames(
            {'mb-2': atsLocations},
            'font-bold text-base lg:text-xl',
          )}
        >
          {customTitle ? customTitle : jobPosting.title}
        </h3>
        <LocationLabel />
      </a>
    </div>
  );
}
