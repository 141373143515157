import classNames from 'classnames';
import ReactDOM from 'react-dom';
import type {ReactElement} from 'react';

import {CareersSearchClear} from '@/components/careers/assets';
import type {JobPostingWithJob} from '@/components/careers/types/ashby';
import {
  getJobDiscipline,
  orderJobDisciplines,
} from '@/components/careers/utils/JobUtil';
import {getCountriesWithHiringEntities} from '@/components/careers/utils/LocationUtil';
import {useTranslations} from '@/hooks/useTranslations';
import {CheckboxField} from '@/components/careers/section/JobPostings/components/application/fields/CheckboxField';

interface JobPostingsFiltersProps {
  formRef: React.RefObject<HTMLFormElement>;
  keywordFilter: string;
  setKeywordFilter: (keyword: string) => void;
  locationFilter: string[];
  setLocatonFilter: (locations: string[]) => void;
  jobPostingsWithJobs: JobPostingWithJob[];
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function JobPostingsFilters({
  formRef,
  keywordFilter,
  setKeywordFilter,
  locationFilter,
  setLocatonFilter,
  jobPostingsWithJobs,
  setModalOpen,
}: JobPostingsFiltersProps) {
  const {t} = useTranslations();
  const displayLocationsFilter = false; // Temporary flag until we can turn locations back on. Right now all postings are global.
  let disciplines: string[] = jobPostingsWithJobs
    .map((jobPostingWithJob: JobPostingWithJob): string => {
      return getJobDiscipline(jobPostingWithJob) || '';
    })
    .filter((jobPostingWithJob) => jobPostingWithJob !== '');

  disciplines = orderJobDisciplines(Array.from(new Set(disciplines)));

  const LocationsFilter = ({sidebar}: {sidebar: boolean}) => {
    return (
      <div
        className={classNames('border-t border-shade-25 pt-5', {
          'relative mt-10': sidebar,
        })}
      >
        <div className="flex pb-6">
          <span className="grow font-bold">{t('jobList.location')}</span>
          <button
            type="button"
            className="text-blue-800 font-bold"
            onClick={() => setLocatonFilter([])}
          >
            {t('jobList.clear')}
          </button>
        </div>

        <ul className="xl:columns-2">
          {getCountriesWithHiringEntities().map((location): ReactElement => {
            return (
              <CheckboxField
                className="mb-3"
                key={location.alpha2Code}
                value={location.alpha2Code}
                label={location.name}
                checked={locationFilter.some(
                  (alpha2Code) => alpha2Code === location.alpha2Code,
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();

                  if (e.target.checked) {
                    setLocatonFilter([...locationFilter, location.alpha2Code]);
                  } else {
                    setLocatonFilter(
                      locationFilter.filter(
                        (alpha2Code) => alpha2Code !== location.alpha2Code,
                      ),
                    );
                  }
                }}
              />
            );
          })}
        </ul>
        <p className="italic text-sm pt-4 text-gray-600">
          {t('jobList.filterLegend')}
        </p>
      </div>
    );
  };

  const FiltersModal = () => {
    if (typeof window !== 'undefined' && document.querySelector('#Modal')) {
      return (
        <>
          {ReactDOM.createPortal(
            <LocationsFilter sidebar={false} />,
            document.querySelector('#Modal') as Element,
          )}
        </>
      );
    } else {
      return <LocationsFilter sidebar={false} />;
    }
  };

  return (
    <form
      id="filter-form"
      ref={formRef}
      onSubmit={(evt) => evt.preventDefault()}
    >
      <div className="mb-12 lg:mb-5 flex">
        <label className="grow">
          <span className="text-gray-600 text-base block mb-2">
            {t('jobList.keywordTitle')}
          </span>
          <div className="relative">
            {keywordFilter.length > 0 && (
              <button
                type="button"
                className="absolute top-1/2 right-5 mt-[-10px]"
                onClick={() => setKeywordFilter('')}
              >
                <span>
                  <CareersSearchClear width={20} height={20} />
                </span>
              </button>
            )}
            <input
              aria-describedby="MessageId_61e4"
              placeholder={t('jobList.keywordPlaceholder')}
              className="rounded-full h-14 p-4 text-lg text-black bg-white w-full border border-black"
              type="text"
              onChange={(evt) => setKeywordFilter(evt.target.value)}
              value={keywordFilter}
              id="search_keywords"
            />
          </div>
        </label>
        {displayLocationsFilter && (
          <>
            <button
              type="button"
              className="mt-9 ml-4 uppercase font-bold lg:hidden"
              onClick={() => setModalOpen(true)}
            >
              {t('jobList.filterLabel')}
            </button>
            <FiltersModal />
          </>
        )}
      </div>
      <div className="hidden lg:block">
        <div className="border-t border-shade-25 mt-10">
          <span className="block my-6 text-base font-bold">
            {t('jobList.departmentTitle')}
          </span>
          <ul className="relative pl-6 border-l border-l-black">
            {disciplines.map((discipline: string | undefined) => {
              return (
                <li key={discipline} className="mb-3">
                  <a href={`#${discipline}`}>{discipline}</a>
                </li>
              );
            })}
          </ul>
        </div>
        {displayLocationsFilter && <LocationsFilter sidebar={true} />}
      </div>
    </form>
  );
}
