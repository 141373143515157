import {useTranslations} from '@/components/shared/Page/I18N';

import Section from '../../shared/Section/Section';

export function BeingExtraordinary() {
  const {t} = useTranslations();

  const supportingContent = (
    <div className="grid lg:grid-cols-3 gap-10 mt-10 md:mt-12">
      <div>
        <h3 className="text-xl md:text-[1.625rem] font-bold pb-4">
          {t('beingExtraordinary.acclaimedMastery.title')}
        </h3>
        <p className="text-base md:text-lg">
          {t('beingExtraordinary.acclaimedMastery.content')}
        </p>
      </div>
      <div>
        <h3 className="text-xl md:text-[1.625rem] font-bold pb-4">
          {t('beingExtraordinary.exceptionallyDriven.title')}
        </h3>
        <p className="text-base md:text-lg">
          {t('beingExtraordinary.exceptionallyDriven.content')}
        </p>
      </div>
      <div>
        <h3 className="text-xl md:text-[1.625rem] font-bold pb-4">
          {t('beingExtraordinary.alternativeSuperpower.title')}
        </h3>
        <p className="text-base md:text-lg">
          {t('beingExtraordinary.alternativeSuperpower.content')}
        </p>
      </div>
    </div>
  );
  return (
    <Section
      heading={t('beingExtraordinary.heading')}
      background="light-blue"
      childPosition="under"
      supportingContent={supportingContent}
    >
      <p>{t('beingExtraordinary.disclaimer')}</p>
    </Section>
  );
}
