import type {ImgHTMLAttributes} from 'react';

export default function CareersPinterestIcon(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/619e217677a08e9bf77e9cb5b32aaf59.svg"
      alt="Pinterest"
      {...props}
    />
  );
}
