import cn from 'classnames';

import {CareersLogoBlack} from '@/components/careers/assets';

import type {NavOptions} from '../NavToggle/NavToggle';
import NavToggle from '../NavToggle/NavToggle';

interface HeaderProps {
  onNavToggle?: (option: NavOptions) => void;
  currentSection?: NavOptions;
  hideNavigation?: boolean;
}

export default function TopHeader({
  currentSection,
  onNavToggle = () => null,
  hideNavigation,
}: HeaderProps) {
  return (
    <div className="lg:flex lg:justify-between border border-transparent">
      <a href="/careers">
        <CareersLogoBlack
          width={200}
          height={52}
          className={cn('block min-w-[200px] mb-8 lg:mb-0 fill-black', {
            'w-[217px] h-[42px]': !hideNavigation,
          })}
        />
      </a>
      {!hideNavigation && currentSection && (
        <NavToggle currentSection={currentSection} onNavToggle={onNavToggle} />
      )}
    </div>
  );
}
