import {
  CareersFacebookIcon,
  CareersInstagramIcon,
  CareersLinkedinIcon,
  CareersPinterestIcon,
  CareersTwitterIcon,
  CareersYoutubeIcon,
} from '@/components/careers/assets';
import {DivHtml} from '@/components/shared/HtmlElements';
import {useSharedTranslations} from '@/hooks/useTranslations';

interface FooterPrograms {
  title: string;
  content: string;
  learnMore: string;
}

export function Footer() {
  const {t} = useSharedTranslations('pages/careers/shared');
  const programs: FooterPrograms[] = t(
    'Footer.programs',
    [],
  ) as FooterPrograms[];
  return (
    <footer
      className="bg-black text-white max-w-screen-3xl m-auto"
      role="contentinfo"
    >
      <div className="pt-14 px-5 md:pt-12 md:px-16 pb-7">
        <div className="lg:flex">
          <div className="lg:w-1/3">
            <h2 className="text-xl lg:text-3xl lg:pr-5 font-bold mb-8">
              <DivHtml>{t('Footer.title')}</DivHtml>
            </h2>
          </div>
          <div className="lg:w-2/3 [&*p]:py-4 [&*h3]:uppercase [&*h3]:text-sm [&*p]:text-sm [&*a]:underline">
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {typeof programs === 'object' &&
                programs?.map(({title, content, learnMore}) => (
                  <div key={title}>
                    <h3 className="font-bold">{title}</h3>
                    <p>{content}</p>
                    <div className="mt-3">
                      <DivHtml className="text-sm">{learnMore}</DivHtml>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="p-5 lg:py-14 md:px-16 border-t border-t-neutral-900">
        <div className="lg:flex">
          <div>
            <ul className="flex justify-center gap-6 mb-6">
              <li>
                <a
                  target="_blank"
                  data-ga-event="Social"
                  data-ga-action="Facebook"
                  data-ga-label="Footer"
                  href="https://www.facebook.com/shopify"
                  rel="noreferrer"
                >
                  <CareersFacebookIcon width={25} height={25} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  data-ga-event="Social"
                  data-ga-action="Twitter"
                  data-ga-label="Footer"
                  href="https://twitter.com/shopify"
                  rel="noreferrer"
                >
                  <CareersTwitterIcon width={25} height={25} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  data-ga-event="Social"
                  data-ga-action="Youtube"
                  data-ga-label="Footer"
                  href="https://www.youtube.com/user/shopify"
                  rel="noreferrer"
                >
                  <CareersYoutubeIcon width={25} height={25} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  data-ga-event="Social"
                  data-ga-action="Instagram"
                  data-ga-label="Footer"
                  href="https://www.instagram.com/shopify/"
                  rel="noreferrer"
                >
                  <CareersInstagramIcon width={25} height={25} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  data-ga-event="Social"
                  data-ga-action="Linkedin"
                  data-ga-label="Footer"
                  href="https://www.linkedin.com/company/shopify"
                  rel="noreferrer"
                >
                  <CareersLinkedinIcon width={25} height={25} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  data-ga-event="Social"
                  data-ga-action="Pinterest"
                  data-ga-label="Footer"
                  href="https://www.pinterest.com/shopify/"
                  rel="noreferrer"
                >
                  <CareersPinterestIcon width={25} height={25} />
                </a>
              </li>
            </ul>
          </div>

          <div className="grow">
            <ul className="md:flex justify-center lg:justify-end gap-4 text-center">
              <li>
                <a
                  data-event-page="careers-home"
                  data-event-category="link"
                  data-event-action="click-link-footer"
                  data-event-label="terms"
                  href="/legal/terms"
                >
                  {t('Footer.terms')}
                </a>
              </li>

              <li>
                <a
                  data-event-page="careers-home"
                  data-event-category="link"
                  data-event-action="click-link-footer"
                  data-event-label="privacy"
                  href="/legal/privacy"
                >
                  {t('Footer.privacy')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
