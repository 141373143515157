import {Link} from '@remix-run/react';

import {useTranslations} from '@/components/shared/Page/I18N';

interface CountryItem {
  emoji: string;
  name: string;
  code: string;
}

interface ContinentItem {
  continent: string;
  countries: CountryItem[];
}

export function CountryList() {
  const {t} = useTranslations();
  const hiringEntities: ContinentItem[] = t('whereWeHire.hiringEntities');

  return (
    <ul className="grid md:grid-cols-4 gap[10px] mt-[60px] lg:mt-[100px]">
      {typeof hiringEntities === 'object' &&
        hiringEntities.map((continent) => {
          const countryFilterList = continent.countries
            .map((country) => {
              return `search[locations][]=${country.code}`;
            })
            .join('&');

          return (
            <li className="mb-[20px] md:mb-0" key={continent.continent}>
              <h3 className="text-xl md:text-2xl font-bold mb-6">
                <Link to={`/careers?${countryFilterList}#Jobs`}>
                  {continent.continent}
                </Link>
              </h3>

              <ul>
                {continent.countries.map((country) => {
                  return (
                    <li
                      className="inline-block md:block md:mr-0 text-base mb-2 mr-2.5"
                      key={country.code}
                    >
                      <a href="/careers#Jobs">
                        <span aria-hidden="true" className="mr-2">
                          {country.emoji}
                        </span>
                        {country.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
    </ul>
  );
}
