interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  hiringEntity?: boolean;
}

interface Location {
  continent: string;
  region: string;
  countries: Country[];
}

export const locations: Location[] = [
  {
    continent: 'North America',
    region: 'Americas',
    countries: [
      {
        name: 'United States',
        alpha2Code: 'US',
        alpha3Code: 'USA',
        hiringEntity: true,
      },
      {
        name: 'Canada',
        alpha2Code: 'CA',
        alpha3Code: 'CAN',
        hiringEntity: true,
      },
    ],
  },
  {
    continent: 'Asia',
    region: 'APAC',
    countries: [
      {
        name: 'Afghanistan',
        alpha2Code: 'AF',
        alpha3Code: 'AFG',
      },
      {
        name: 'Armenia',
        alpha2Code: 'AM',
        alpha3Code: 'ARM',
      },
      {
        name: 'Azerbaijan',
        alpha2Code: 'AZ',
        alpha3Code: 'AZE',
      },
      {
        name: 'Bahrain',
        alpha2Code: 'BH',
        alpha3Code: 'BHR',
      },
      {
        name: 'Bangladesh',
        alpha2Code: 'BD',
        alpha3Code: 'BGD',
      },
      {
        name: 'Bhutan',
        alpha2Code: 'BT',
        alpha3Code: 'BTN',
      },
      {
        name: 'Brunei',
        alpha2Code: 'BN',
        alpha3Code: 'BRN',
      },
      {
        name: 'Cambodia',
        alpha2Code: 'KH',
        alpha3Code: 'KHM',
      },
      {
        name: 'China',
        alpha2Code: 'CN',
        alpha3Code: 'CHN',
        hiringEntity: true,
      },
      {
        name: 'Georgia',
        alpha2Code: 'GE',
        alpha3Code: 'GEO',
      },
      {
        name: 'Hong Kong SAR',
        alpha2Code: 'HK',
        alpha3Code: 'HKG',
      },
      {
        name: 'India',
        alpha2Code: 'IN',
        alpha3Code: 'IND',
        hiringEntity: true,
      },
      {
        name: 'Indonesia',
        alpha2Code: 'ID',
        alpha3Code: 'IDN',
      },
      {
        name: 'Iran',
        alpha2Code: 'IR',
        alpha3Code: 'IRN',
      },
      {
        name: 'Iraq',
        alpha2Code: 'IQ',
        alpha3Code: 'IRQ',
      },
      {
        name: 'Israel',
        alpha2Code: 'IL',
        alpha3Code: 'ISR',
        hiringEntity: true,
      },
      {
        name: 'Japan',
        alpha2Code: 'JP',
        alpha3Code: 'JPN',
        hiringEntity: true,
      },
      {
        name: 'Jordan',
        alpha2Code: 'JO',
        alpha3Code: 'JOR',
      },
      {
        name: 'Kazakhstan',
        alpha2Code: 'KZ',
        alpha3Code: 'KAZ',
      },
      {
        name: 'Kuwait',
        alpha2Code: 'KW',
        alpha3Code: 'KWT',
      },
      {
        name: 'Kyrgyzstan',
        alpha2Code: 'KG',
        alpha3Code: 'KGZ',
      },
      {
        name: 'Laos',
        alpha2Code: 'LA',
        alpha3Code: 'LAO',
      },
      {
        name: 'Lebanon',
        alpha2Code: 'LB',
        alpha3Code: 'LBN',
      },
      {
        name: 'Macao SAR',
        alpha2Code: 'MO',
        alpha3Code: 'MAC',
      },
      {
        name: 'Malaysia',
        alpha2Code: 'MY',
        alpha3Code: 'MYS',
      },
      {
        name: 'Maldives',
        alpha2Code: 'MV',
        alpha3Code: 'MDV',
      },
      {
        name: 'Mongolia',
        alpha2Code: 'MN',
        alpha3Code: 'MNG',
      },
      {
        name: 'Myanmar (Burma)',
        alpha2Code: 'MM',
        alpha3Code: 'MMR',
      },
      {
        name: 'Nepal',
        alpha2Code: 'NP',
        alpha3Code: 'NPL',
      },
      {
        name: 'North Korea',
        alpha2Code: 'KP',
        alpha3Code: 'PRK',
      },
      {
        name: 'Oman',
        alpha2Code: 'OM',
        alpha3Code: 'OMN',
      },
      {
        name: 'Pakistan',
        alpha2Code: 'PK',
        alpha3Code: 'PAK',
      },
      {
        name: 'Palestinian Territories',
        alpha2Code: 'PS',
        alpha3Code: 'PSE',
      },
      {
        name: 'Philippines',
        alpha2Code: 'PH',
        alpha3Code: 'PHL',
      },
      {
        name: 'Qatar',
        alpha2Code: 'QA',
        alpha3Code: 'QAT',
      },
      {
        name: 'Russia',
        alpha2Code: 'RU',
        alpha3Code: 'RUS',
      },
      {
        name: 'Saudi Arabia',
        alpha2Code: 'SA',
        alpha3Code: 'SAU',
      },
      {
        name: 'Singapore',
        alpha2Code: 'SG',
        alpha3Code: 'SGP',
        hiringEntity: true,
      },
      {
        name: 'South Korea',
        alpha2Code: 'KR',
        alpha3Code: 'KOR',
      },
      {
        name: 'Sri Lanka',
        alpha2Code: 'LK',
        alpha3Code: 'LKA',
      },
      {
        name: 'Syria',
        alpha2Code: 'SY',
        alpha3Code: 'SYR',
      },
      {
        name: 'Taiwan',
        alpha2Code: 'TW',
        alpha3Code: 'TWN',
      },
      {
        name: 'Tajikistan',
        alpha2Code: 'TJ',
        alpha3Code: 'TJK',
      },
      {
        name: 'Thailand',
        alpha2Code: 'TH',
        alpha3Code: 'THA',
      },
      {
        name: 'Turkey',
        alpha2Code: 'TR',
        alpha3Code: 'TUR',
      },
      {
        name: 'Turkmenistan',
        alpha2Code: 'TM',
        alpha3Code: 'TKM',
      },
      {
        name: 'United Arab Emirates',
        alpha2Code: 'AE',
        alpha3Code: 'ARE',
      },
      {
        name: 'Uzbekistan',
        alpha2Code: 'UZ',
        alpha3Code: 'UZB',
      },
      {
        name: 'Vietnam',
        alpha2Code: 'VN',
        alpha3Code: 'VNM',
      },
      {
        name: 'Yemen',
        alpha2Code: 'YE',
        alpha3Code: 'YEM',
      },
    ],
  },
  {
    continent: 'Europe',
    region: 'EMEA',
    countries: [
      {
        name: 'Albania',
        alpha2Code: 'AL',
        alpha3Code: 'ALB',
      },
      {
        name: 'Andorra',
        alpha2Code: 'AD',
        alpha3Code: 'AND',
      },
      {
        name: 'Armenia',
        alpha2Code: 'AM',
        alpha3Code: 'ARM',
      },
      {
        name: 'Austria',
        alpha2Code: 'AT',
        alpha3Code: 'AUT',
      },
      {
        name: 'Azerbaijan',
        alpha2Code: 'AZ',
        alpha3Code: 'AZE',
      },
      {
        name: 'Belarus',
        alpha2Code: 'BY',
        alpha3Code: 'BLR',
      },
      {
        name: 'Belgium',
        alpha2Code: 'BE',
        alpha3Code: 'BEL',
      },
      {
        name: 'Bosnia & Herzegovina',
        alpha2Code: 'BA',
        alpha3Code: 'BIH',
      },
      {
        name: 'Bulgaria',
        alpha2Code: 'BG',
        alpha3Code: 'BGR',
      },
      {
        name: 'Croatia',
        alpha2Code: 'HR',
        alpha3Code: 'HRV',
      },
      {
        name: 'Cyprus',
        alpha2Code: 'CY',
        alpha3Code: 'CYP',
      },
      {
        name: 'Czechia',
        alpha2Code: 'CZ',
        alpha3Code: 'CZE',
      },
      {
        name: 'Denmark',
        alpha2Code: 'DK',
        alpha3Code: 'DNK',
      },
      {
        name: 'Estonia',
        alpha2Code: 'EE',
        alpha3Code: 'EST',
      },
      {
        name: 'Finland',
        alpha2Code: 'FI',
        alpha3Code: 'FIN',
      },
      {
        name: 'France',
        alpha2Code: 'FR',
        alpha3Code: 'FRA',
        hiringEntity: true,
      },
      {
        name: 'Georgia',
        alpha2Code: 'GE',
        alpha3Code: 'GEO',
      },
      {
        name: 'Germany',
        alpha2Code: 'DE',
        alpha3Code: 'DEU',
        hiringEntity: true,
      },
      {
        name: 'Greece',
        alpha2Code: 'GR',
        alpha3Code: 'GRC',
      },
      {
        name: 'Hungary',
        alpha2Code: 'HU',
        alpha3Code: 'HUN',
      },
      {
        name: 'Iceland',
        alpha2Code: 'IS',
        alpha3Code: 'ISL',
      },
      {
        name: 'Ireland',
        alpha2Code: 'IE',
        alpha3Code: 'IRL',
        hiringEntity: true,
      },
      {
        name: 'Italy',
        alpha2Code: 'IT',
        alpha3Code: 'ITA',
        hiringEntity: true,
      },
      {
        name: 'Kazakhstan',
        alpha2Code: 'KZ',
        alpha3Code: 'KAZ',
      },
      {
        name: 'Kosovo',
        alpha2Code: 'XK',
        alpha3Code: 'XKX',
      },
      {
        name: 'Latvia',
        alpha2Code: 'LV',
        alpha3Code: 'LVA',
      },
      {
        name: 'Liechtenstein',
        alpha2Code: 'LI',
        alpha3Code: 'LIE',
      },
      {
        name: 'Lithuania',
        alpha2Code: 'LT',
        alpha3Code: 'LTU',
        hiringEntity: true,
      },
      {
        name: 'Luxembourg',
        alpha2Code: 'LU',
        alpha3Code: 'LUX',
      },
      {
        name: 'Malta',
        alpha2Code: 'MT',
        alpha3Code: 'MLT',
      },
      {
        name: 'Moldova',
        alpha2Code: 'MD',
        alpha3Code: 'MDA',
      },
      {
        name: 'Monaco',
        alpha2Code: 'MC',
        alpha3Code: 'MCO',
      },
      {
        name: 'Montenegro',
        alpha2Code: 'ME',
        alpha3Code: 'MNE',
      },
      {
        name: 'Netherlands',
        alpha2Code: 'NL',
        alpha3Code: 'NLD',
        hiringEntity: true,
      },
      {
        name: 'North Macedonia',
        alpha2Code: 'MK',
        alpha3Code: 'MKD',
      },
      {
        name: 'Norway',
        alpha2Code: 'NO',
        alpha3Code: 'NOR',
      },
      {
        name: 'Poland',
        alpha2Code: 'PL',
        alpha3Code: 'POL',
        hiringEntity: true,
      },
      {
        name: 'Portugal',
        alpha2Code: 'PT',
        alpha3Code: 'PRT',
      },
      {
        name: 'Romania',
        alpha2Code: 'RO',
        alpha3Code: 'ROU',
      },
      {
        name: 'Russia',
        alpha2Code: 'RU',
        alpha3Code: 'RUS',
      },
      {
        name: 'San Marino',
        alpha2Code: 'SM',
        alpha3Code: 'SMR',
      },
      {
        name: 'Serbia',
        alpha2Code: 'RS',
        alpha3Code: 'SRB',
      },
      {
        name: 'Slovakia',
        alpha2Code: 'SK',
        alpha3Code: 'SVK',
      },
      {
        name: 'Slovenia',
        alpha2Code: 'SI',
        alpha3Code: 'SVN',
      },
      {
        name: 'Spain',
        alpha2Code: 'ES',
        alpha3Code: 'ESP',
        hiringEntity: true,
      },
      {
        name: 'Sweden',
        alpha2Code: 'SE',
        alpha3Code: 'SWE',
        hiringEntity: true,
      },
      {
        name: 'Switzerland',
        alpha2Code: 'CH',
        alpha3Code: 'CHE',
      },
      {
        name: 'Turkey',
        alpha2Code: 'TR',
        alpha3Code: 'TUR',
      },
      {
        name: 'Ukraine',
        alpha2Code: 'UA',
        alpha3Code: 'UKR',
      },
      {
        name: 'United Kingdom',
        alpha2Code: 'GB',
        alpha3Code: 'GBR',
        hiringEntity: true,
      },
      {
        name: 'Vatican City',
        alpha2Code: 'VA',
        alpha3Code: 'VAT',
      },
    ],
  },
  {
    continent: 'Oceania',
    region: 'APAC',
    countries: [
      {
        name: 'American Samoa',
        alpha2Code: 'AS',
        alpha3Code: 'ASM',
      },
      {
        name: 'Australia',
        alpha2Code: 'AU',
        alpha3Code: 'AUS',
        hiringEntity: true,
      },
      {
        name: 'Cook Islands',
        alpha2Code: 'CK',
        alpha3Code: 'COK',
      },
      {
        name: 'Fiji',
        alpha2Code: 'FJ',
        alpha3Code: 'FJI',
      },
      {
        name: 'French Polynesia',
        alpha2Code: 'PF',
        alpha3Code: 'PYF',
      },
      {
        name: 'Guam',
        alpha2Code: 'GU',
        alpha3Code: 'GUM',
      },
      {
        name: 'Kiribati',
        alpha2Code: 'KI',
        alpha3Code: 'KIR',
      },
      {
        name: 'Marshall Islands',
        alpha2Code: 'MH',
        alpha3Code: 'MHL',
      },
      {
        name: 'Micronesia',
        alpha2Code: 'FM',
        alpha3Code: 'FSM',
      },
      {
        name: 'Nauru',
        alpha2Code: 'NR',
        alpha3Code: 'NRU',
      },
      {
        name: 'New Caledonia',
        alpha2Code: 'NC',
        alpha3Code: 'NCL',
      },
      {
        name: 'New Zealand',
        alpha2Code: 'NZ',
        alpha3Code: 'NZL',
        hiringEntity: true,
      },
      {
        name: 'Niue',
        alpha2Code: 'NU',
        alpha3Code: 'NIU',
      },
      {
        name: 'Norfolk Island',
        alpha2Code: 'NF',
        alpha3Code: 'NFK',
      },
      {
        name: 'Northern Mariana Islands',
        alpha2Code: 'MP',
        alpha3Code: 'MNP',
      },
      {
        name: 'Palau',
        alpha2Code: 'PW',
        alpha3Code: 'PLW',
      },
      {
        name: 'Papua New Guinea',
        alpha2Code: 'PG',
        alpha3Code: 'PNG',
      },
      {
        name: 'Pitcairn Islands',
        alpha2Code: 'PN',
        alpha3Code: 'PCN',
      },
      {
        name: 'Samoa',
        alpha2Code: 'WS',
        alpha3Code: 'WSM',
      },
      {
        name: 'Solomon Islands',
        alpha2Code: 'SB',
        alpha3Code: 'SLB',
      },
      {
        name: 'Tokelau',
        alpha2Code: 'TK',
        alpha3Code: 'TKL',
      },
      {
        name: 'Tonga',
        alpha2Code: 'TO',
        alpha3Code: 'TON',
      },
      {
        name: 'Tuvalu',
        alpha2Code: 'TV',
        alpha3Code: 'TUV',
      },
      {
        name: 'U.S. Outlying Islands',
        alpha2Code: 'UM',
        alpha3Code: 'UMI',
      },
      {
        name: 'Vanuatu',
        alpha2Code: 'VU',
        alpha3Code: 'VUT',
      },
      {
        name: 'Wallis & Futuna',
        alpha2Code: 'WF',
        alpha3Code: 'WLF',
      },
    ],
  },
  {
    continent: 'Africa',
    region: 'EMEA',
    countries: [
      {
        name: 'Algeria',
        alpha2Code: 'DZ',
        alpha3Code: 'DZA',
      },
      {
        name: 'Angola',
        alpha2Code: 'AO',
        alpha3Code: 'AGO',
      },
      {
        name: 'Benin',
        alpha2Code: 'BJ',
        alpha3Code: 'BEN',
      },
      {
        name: 'Botswana',
        alpha2Code: 'BW',
        alpha3Code: 'BWA',
      },
      {
        name: 'Burkina Faso',
        alpha2Code: 'BF',
        alpha3Code: 'BFA',
      },
      {
        name: 'Burundi',
        alpha2Code: 'BI',
        alpha3Code: 'BDI',
      },
      {
        name: 'Cabo Verde',
        alpha2Code: 'CV',
        alpha3Code: 'CPV',
      },
      {
        name: 'Cameroon',
        alpha2Code: 'CM',
        alpha3Code: 'CMR',
      },
      {
        name: 'Central African Republic',
        alpha2Code: 'CF',
        alpha3Code: 'CAF',
      },
      {
        name: 'Chad',
        alpha2Code: 'TD',
        alpha3Code: 'TCD',
      },
      {
        name: 'Comoros',
        alpha2Code: 'KM',
        alpha3Code: 'COM',
      },
      {
        name: 'Congo - Brazzaville',
        alpha2Code: 'CG',
        alpha3Code: 'COG',
      },
      {
        name: 'Congo - Kinshasa',
        alpha2Code: 'CD',
        alpha3Code: 'COD',
      },
      {
        name: 'Côte d’Ivoire',
        alpha2Code: 'CI',
        alpha3Code: 'CIV',
      },
      {
        name: 'Djibouti',
        alpha2Code: 'DJ',
        alpha3Code: 'DJI',
      },
      {
        name: 'Egypt',
        alpha2Code: 'EG',
        alpha3Code: 'EGY',
      },
      {
        name: 'Equatorial Guinea',
        alpha2Code: 'GQ',
        alpha3Code: 'GNQ',
      },
      {
        name: 'Eritrea',
        alpha2Code: 'ER',
        alpha3Code: 'ERI',
      },
      {
        name: 'Eswatini',
        alpha2Code: 'SZ',
        alpha3Code: 'SWZ',
      },
      {
        name: 'Ethiopia',
        alpha2Code: 'ET',
        alpha3Code: 'ETH',
      },
      {
        name: 'Gabon',
        alpha2Code: 'GA',
        alpha3Code: 'GAB',
      },
      {
        name: 'Gambia',
        alpha2Code: 'GM',
        alpha3Code: 'GMB',
      },
      {
        name: 'Ghana',
        alpha2Code: 'GH',
        alpha3Code: 'GHA',
      },
      {
        name: 'Guinea',
        alpha2Code: 'GN',
        alpha3Code: 'GIN',
      },
      {
        name: 'Guinea-Bissau',
        alpha2Code: 'GW',
        alpha3Code: 'GNB',
      },
      {
        name: 'Kenya',
        alpha2Code: 'KE',
        alpha3Code: 'KEN',
      },
      {
        name: 'Lesotho',
        alpha2Code: 'LS',
        alpha3Code: 'LSO',
      },
      {
        name: 'Liberia',
        alpha2Code: 'LR',
        alpha3Code: 'LBR',
      },
      {
        name: 'Libya',
        alpha2Code: 'LY',
        alpha3Code: 'LBY',
      },
      {
        name: 'Madagascar',
        alpha2Code: 'MG',
        alpha3Code: 'MDG',
      },
      {
        name: 'Malawi',
        alpha2Code: 'MW',
        alpha3Code: 'MWI',
      },
      {
        name: 'Mali',
        alpha2Code: 'ML',
        alpha3Code: 'MLI',
      },
      {
        name: 'Mauritania',
        alpha2Code: 'MR',
        alpha3Code: 'MRT',
      },
      {
        name: 'Mauritius',
        alpha2Code: 'MU',
        alpha3Code: 'MUS',
      },
      {
        name: 'Morocco',
        alpha2Code: 'MA',
        alpha3Code: 'MAR',
      },
      {
        name: 'Mozambique',
        alpha2Code: 'MZ',
        alpha3Code: 'MOZ',
      },
      {
        name: 'Namibia',
        alpha2Code: 'NA',
        alpha3Code: 'NAM',
      },
      {
        name: 'Niger',
        alpha2Code: 'NE',
        alpha3Code: 'NER',
      },
      {
        name: 'Nigeria',
        alpha2Code: 'NG',
        alpha3Code: 'NGA',
      },
      {
        name: 'Rwanda',
        alpha2Code: 'RW',
        alpha3Code: 'RWA',
      },
      {
        name: 'São Tomé & Príncipe',
        alpha2Code: 'ST',
        alpha3Code: 'STP',
      },
      {
        name: 'Senegal',
        alpha2Code: 'SN',
        alpha3Code: 'SEN',
      },
      {
        name: 'Seychelles',
        alpha2Code: 'SC',
        alpha3Code: 'SYC',
      },
      {
        name: 'Sierra Leone',
        alpha2Code: 'SL',
        alpha3Code: 'SLE',
      },
      {
        name: 'Somalia',
        alpha2Code: 'SO',
        alpha3Code: 'SOM',
      },
      {
        name: 'South Africa',
        alpha2Code: 'ZA',
        alpha3Code: 'ZAF',
      },
      {
        name: 'South Sudan',
        alpha2Code: 'SS',
        alpha3Code: 'SSD',
      },
      {
        name: 'Sudan',
        alpha2Code: 'SD',
        alpha3Code: 'SDN',
      },
      {
        name: 'Tanzania',
        alpha2Code: 'TZ',
        alpha3Code: 'TZA',
      },
      {
        name: 'Togo',
        alpha2Code: 'TG',
        alpha3Code: 'TGO',
      },
      {
        name: 'Tunisia',
        alpha2Code: 'TN',
        alpha3Code: 'TUN',
      },
      {
        name: 'Uganda',
        alpha2Code: 'UG',
        alpha3Code: 'UGA',
      },
      {
        name: 'Zambia',
        alpha2Code: 'ZM',
        alpha3Code: 'ZMB',
      },
      {
        name: 'Zimbabwe',
        alpha2Code: 'ZW',
        alpha3Code: 'ZWE',
      },
    ],
  },
  {
    continent: 'South America',
    region: 'Americas',
    countries: [
      {
        name: 'Argentina',
        alpha2Code: 'AR',
        alpha3Code: 'ARG',
      },
      {
        name: 'Bolivia',
        alpha2Code: 'BO',
        alpha3Code: 'BOL',
      },
      {
        name: 'Brazil',
        alpha2Code: 'BR',
        alpha3Code: 'BRA',
      },
      {
        name: 'Chile',
        alpha2Code: 'CL',
        alpha3Code: 'CHL',
      },
      {
        name: 'Colombia',
        alpha2Code: 'CO',
        alpha3Code: 'COL',
      },
      {
        name: 'Ecuador',
        alpha2Code: 'EC',
        alpha3Code: 'ECU',
      },
      {
        name: 'Guyana',
        alpha2Code: 'GY',
        alpha3Code: 'GUY',
      },
      {
        name: 'Paraguay',
        alpha2Code: 'PY',
        alpha3Code: 'PRY',
      },
      {
        name: 'Peru',
        alpha2Code: 'PE',
        alpha3Code: 'PER',
      },
      {
        name: 'Suriname',
        alpha2Code: 'SR',
        alpha3Code: 'SUR',
      },
      {
        name: 'Uruguay',
        alpha2Code: 'UY',
        alpha3Code: 'URY',
      },
      {
        name: 'Venezuela',
        alpha2Code: 'VE',
        alpha3Code: 'VEN',
      },
    ],
  },
];
