import type {ImgHTMLAttributes} from 'react';

export default function CareersInstagramIcon(
  props: ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      src="https://cdn.shopify.com/b/shopify-brochure2-assets/8a748a106483abd64d0f1958234dcc1b.svg"
      alt="Instagram"
      {...props}
    />
  );
}
